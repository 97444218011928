import AppRoutes from './App.Routes';
import AuthRoutes from './Auth.Routes';
import { useAuth } from '../contexts/Auth';
import { LoadingScreen } from '../components/LoadingScreen';

export default function IndexRoutes() {
    const { signed, loading } = useAuth();

    if (loading) {
        return <LoadingScreen />;
    }

    return signed ? <AppRoutes /> : <AuthRoutes />;
}
