import React, { useState } from 'react';
import Api from '../../configs/Api';

interface Invoice {
    id: number;
    mes_referencia: string;
    link: string; // URL da fatura para download
}

const Library: React.FC = () => {
    const [numeroCliente, setNumeroCliente] = useState<string>('');
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Função para buscar todas as faturas do cliente
    const fetchInvoices = async () => {
        if (!numeroCliente) {
            setError('Por favor, insira o Número do Cliente.');
            return;
        }

        try {
            setLoading(true);
            setError(null);

            // Busca as faturas na API
            const response = await Api.get(`/installations/faturas/${numeroCliente}`);

            if (response.status === 200) {
                setInvoices(response.data);
            } else {
                setError(`Erro ao buscar faturas: ${response.status}`);
            }
        } catch (err) {
            setError('Erro ao buscar faturas. Por favor, tente novamente.');
        } finally {
            setLoading(false);
        }
    };

    // Função para baixar fatura
    const downloadInvoice = async (invoiceId: string) => {
        try {
          // Faz a requisição para o endpoint de download
          const response = await Api.get(`/installations/download/${invoiceId}`, {
            responseType: 'blob', // Recebe o arquivo como blob
          });
      
          // Cria um blob URL para o arquivo PDF
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `fatura_${invoiceId}.pdf`; // Nome do arquivo ao baixar
          document.body.appendChild(link); // Anexa o link ao DOM
          link.click(); // Simula o clique para iniciar o download
          document.body.removeChild(link); // Remove o link após o clique
        } catch (error) {
          console.error('Erro ao baixar a fatura:', error);
        }
      };

    return (
        <div className="container">
            <h1 className="h3 mb-4">Biblioteca de Faturas</h1>

            {/* Campo de entrada do número do cliente */}
            <div className="row mb-4">
                <div className="col-md-8">
                    <label className="form-label">Número do Cliente</label>
                    <input
                        type="text"
                        className="form-control"
                        value={numeroCliente}
                        onChange={(e) => setNumeroCliente(e.target.value)}
                        placeholder="Digite o Nº do Cliente"
                    />
                </div>
                <div className="col-md-4 d-flex align-items-end">
                    <button className="btn btn-primary" onClick={fetchInvoices}>
                        Buscar Faturas
                    </button>
                </div>
            </div>

            {/* Exibição de resultados */}
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Carregando...</span>
                    </div>
                </div>
            ) : error ? (
                <div className="alert alert-danger my-4">{error}</div>
            ) : (
                <div className="row">
                    {invoices.length > 0 ? (
                        <table className="table table-striped">
                            <thead className="table-dark">
                                <tr>
                                    <th>Mês de Referência</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map((invoice) => (
                                    <tr key={invoice.id}>
                                        <td>{invoice.mes_referencia}</td>
                                        <td>
                                        <button
                                            className="btn btn-success"
                                            onClick={() => downloadInvoice(String(invoice.id))}
                                            >
                                            Baixar Fatura
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="alert alert-info my-4">Nenhuma fatura encontrada para o número do cliente inserido.</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Library;
