import React, { useEffect } from 'react';
import PDFUpload from '../../components/PDFUpload';

const Upload: React.FC = () => 
{

    useEffect(() => {
        document.title = "Lumi - Upload de Faturas";
    }, []);

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <PDFUpload />
                </div>
            </div>
        </div>
    );
};

export default Upload;
