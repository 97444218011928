import React from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar: React.FC = () => {
    return (
        <nav 
            className="navbar navbar-expand-lg navbar-dark d-flex flex-column p-3"
            style={{ 
                height: '100vh',
                width: '250px',
                background: 'linear-gradient(to bottom, #064e3b, #0f766e)', // Gradiente de verde escuro para intermediário
            }}
        >
            <NavLink to="/" className="navbar-brand mb-3">
                <div className="d-flex align-items-center">
                    <i className="fas fa-laugh-wink mr-2"></i>
                    <span>Lumi Extract</span>
                </div>
            </NavLink>

            <ul className="navbar-nav flex-column w-100">
                <li className="nav-item">
                    <NavLink to="/" className="nav-link">
                        <i className="fas fa-fw fa-tachometer-alt"></i> Dashboard
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/library" className="nav-link">
                        <i className="fas fa-fw fa-wrench"></i> Biblioteca de Faturas
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/installations" className="nav-link">
                        <i className="fas fa-fw fa-cog"></i> Lista de Instalações
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/uploads" className="nav-link">
                        <i className="fas fa-fw fa-wrench"></i> Upload Faturas
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default Sidebar;
