import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout';
import Dashboard from '../views/app/Dashboard';
import Library from '../views/app/Library';
import Installations from '../views/app/Installations';
import InstallationDetails from '../views/app/InstallationDetails';
import Upload from '../views/app/Upload';

const AppRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="/library" element={<Library />} />
                <Route path="/installations" element={<Installations />} />
                <Route path="/installations/:numero_instalacao" element={<InstallationDetails />} />
                <Route path="/uploads" element={<Upload />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
