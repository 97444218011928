import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar o useNavigate
import { IAuth } from './interfaces/IAuth';
import Api from "../configs/Api";

const AuthContext = createContext<IAuth>({} as IAuth);

interface ProviderProps {
    children: React.ReactNode;
}

export const AuthProvider: React.FC<ProviderProps> = ({ children }) => {
    const [token, setToken] = useState<string>('');
    const [authError, setAuthError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate(); // Inicializar o useNavigate

    const loadStoragedData = useCallback(() => {
        const storedToken = localStorage.getItem("token");
        const expiration = localStorage.getItem("expiration");

        if (storedToken && expiration && new Date(expiration) > new Date()) {
            setToken(storedToken);
            Api.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
        } else {
            handleSignOut(); // Chamar o handleSignOut para redirecionar
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        loadStoragedData();
    }, [loadStoragedData]);

    return (
        <AuthContext.Provider
            value={{
                signed: !!token,
                token: token,
                signIn,
                signOut,
                loading,
                authError,
            }}
        >
            {children}
        </AuthContext.Provider>
    );

    async function signIn(username: string, password: string) {
        setLoading(true);
        try {
            const response = await Api.post('/auth/login', { email: username, password });

            if (response.status !== 200) {
                setAuthError(`Status Code: ${response.status} Falha no login. Verifique suas credenciais.`);
            } else {
                const { token, expiresAt } = response.data;
                localStorage.setItem('token', token);
                localStorage.setItem('expiration', expiresAt);
                setToken(token);
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                setAuthError(null);
            }
        } catch (error) {
            setAuthError('Erro ao fazer login. Por favor, tente novamente.');
        } finally {
            setLoading(false);
        }
    }

    function signOut() {
        setToken('');
        localStorage.clear();
        setAuthError(null);
        navigate('/'); // Redireciona para a URL raiz
    }

    // Função para deslogar e redirecionar
    function handleSignOut() {
        setToken('');
        localStorage.clear();
        setAuthError(null);
        navigate('/'); // Redireciona para a URL raiz
    }
};

export function useAuth() {
    return useContext(AuthContext);
}
