import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface TableProps {
    headers: string[];
    data: Array<Array<string | number>>;
}

const Table: React.FC<TableProps> = ({ headers, data }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const navigate = useNavigate(); // Para redirecionar o usuário

    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Função para obter os dados da página atual
    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data.slice(startIndex, endIndex);
    };

    // Função para alternar de página
    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    // Função para lidar com a mudança no número de registros exibidos
    const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1); // Resetar para a primeira página
    };

    // Função para manipular ações nos botões da tabela
    const handleAction = (rowData: Array<string | number>, action: string) => {
        if (action === 'Visualizar') {
            const numeroCliente = rowData[1]; // Assumindo que o segundo valor é o número do cliente
            navigate(`/installations/${numeroCliente}`);
        }
    };

    return (
        <div className="table-responsive w-100">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                    <label htmlFor="itemsPerPage" className="me-2">Mostrar:</label>
                    <select 
                        id="itemsPerPage" 
                        className="form-select d-inline-block w-auto"
                        value={itemsPerPage} 
                        onChange={handleItemsPerPageChange}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                    </select>
                </div>
            </div>

            <table className="table table-striped table-hover w-100">
                <thead className="thead-dark">
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index} scope="col">{header}</th>
                        ))}
                        <th scope="col">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {getPaginatedData().map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                            ))}
                            <td>
                                <button 
                                    className="btn btn-primary btn-sm me-2"
                                    onClick={() => handleAction(row, 'Visualizar')}
                                >
                                    Visualizar
                                </button>
                                <button 
                                    className="btn btn-danger btn-sm"
                                    onClick={() => handleAction(row, 'Excluir')}
                                >
                                    Excluir
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <nav className="d-flex justify-content-end mt-3">
                <ul className="pagination mb-0">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button 
                            className="page-link" 
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Anterior
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li 
                            key={index} 
                            className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                        >
                            <button 
                                className="page-link" 
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button 
                            className="page-link" 
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Próximo
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Table;