import React, { useState } from 'react';
import Api from '../configs/Api';

const PDFUpload: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false); // Estado para controlar o loader

  // Função para manipular os arquivos selecionados
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const pdfFiles = files.filter(file => file.type === 'application/pdf');

    if (pdfFiles.length > 0) {
      setSelectedFiles(pdfFiles);
      setUploadStatus(null);
    } else {
      setSelectedFiles([]);
      setUploadStatus('Por favor, selecione apenas arquivos PDF.');
    }
  };

  // Função para realizar o upload dos arquivos
  const handleFileUpload = async () => {
    if (selectedFiles.length === 0) {
      setUploadStatus('Nenhum arquivo selecionado.');
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach(file => formData.append('files', file));

    setIsUploading(true); // Inicia o loader
    setUploadStatus(null); // Limpa o status anterior

    try {
      const response = await Api.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        setUploadStatus('Os arquivos foram enviados e processados com sucesso!');
      } else {
        setUploadStatus('Falha ao enviar os arquivos. Tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao enviar os arquivos:', error);
      setUploadStatus('Erro ao enviar os arquivos. Verifique sua conexão e tente novamente.');
    } finally {
      setIsUploading(false); // Para o loader
      setSelectedFiles([]); // Limpa os arquivos selecionados
    }
  };

  return (
    <div className="pdf-upload-container">
      <h3 className="mb-4">Upload de PDFs</h3>
      <div className="mb-3">
        <input
          type="file"
          accept="application/pdf"
          multiple
          onChange={handleFileChange}
          className="form-control"
          disabled={isUploading} // Desativa o campo de upload enquanto carrega
        />
      </div>
      <button
        className="btn btn-primary"
        onClick={handleFileUpload}
        disabled={selectedFiles.length === 0 || isUploading} // Desativa o botão enquanto carrega
      >
        {isUploading ? 'Enviando...' : 'Enviar PDFs'} {/* Texto do botão durante o upload */}
      </button>
      {isUploading && (
        <div className="mt-3 spinner-border text-primary" role="status">
          <span className="visually-hidden">Carregando...</span>
        </div>
      )}
      {uploadStatus && (
        <div
          className={`mt-3 alert ${
            uploadStatus.includes('sucesso') ? 'alert-success' : 'alert-danger'
          }`}
          role="alert"
        >
          {uploadStatus}
        </div>
      )}
    </div>
  );
};

export default PDFUpload;
