import React, { useEffect, useState } from 'react';
import Api from '../../configs/Api';
import Table from '../../components/Table';

const Installations: React.FC = () => {
    const [installations, setInstallations] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // Cabeçalhos da tabela
    const headers = ['Número de Instalação', 'Número do Cliente'];

    // Função para buscar as instalações do servidor
    const fetchInstallations = async () => {
        try {
            setLoading(true);
            setError(null);

            // Fazer a requisição usando a instância do Api
            const response = await Api.get('/installations');

            if (response.status === 200) {
                setInstallations(response.data);
            } else {
                setError(`Erro ao buscar instalações: ${response.status}`);
            }
        } catch (err) {
            setError('Erro ao buscar instalações. Por favor, tente novamente.');
        } finally {
            setLoading(false);
        }
    };

    // Buscar as instalações quando o componente for montado
    useEffect(() => {
        fetchInstallations();
        document.title = "Lumi - Lista de Instalações";
    }, []);

    // Preparar os dados para a tabela
    const data = installations.map(installation => [
        installation.numero_instalacao,
        installation.numero_cliente
    ]);

    return (
        <div>
            <h1 className="h3 mb-4">Lista de Instalações</h1>

            {loading ? (
                <p>Carregando...</p>
            ) : error ? (
                <p className="text-danger">{error}</p>
            ) : (
                <Table headers={headers} data={data} />
            )}
        </div>
    );
};

export default Installations;
