import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../../configs/Api';

interface BillingValue {
    id: number;
    installation_id: number;
    item: string;
    unidade: string;
    quantidade: string | null;
    preco_unitario: string | null;
    valor: string;
    mes_referencia: string;
}

interface ConsumptionHistory {
    id: number;
    installation_id: number;
    mes_ano: string;
    consumo: number;
    media: string;
    dias: number;
}

interface PaymentInfo {
    id: number;
    installation_id: number;
    mes_referencia: string;
    vencimento: string | null;
    total_pagar: string | null;
}

interface Installation {
    id: number;
    numero_instalacao: string;
    numero_cliente: string;
    createdAt: string;
    updatedAt: string;
    billingValues: BillingValue[];
    consumptionHistory: ConsumptionHistory[];
    paymentInfo: PaymentInfo[];
}

interface MonthlyCalculation {
    mesReferencia: string;
    consumoEnergiaEletrica: number;
    energiaCompensada: number;
    valorTotalSemGD: number;
    economiaGD: number;
}

const InstallationDetails: React.FC = () => {
    const { numero_instalacao } = useParams<{ numero_instalacao: string }>();
    const [installation, setInstallation] = useState<Installation | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchInstallationDetails = async () => {
        try {
            setLoading(true);
            const response = await Api.get(`/installations/${numero_instalacao}`);
            if (response.status === 200) {
                setInstallation(response.data);
            } else {
                setError(`Erro ao buscar dados: ${response.status}`);
            }
        } catch (err) {
            setError('Erro ao buscar dados da instalação. Por favor, tente novamente.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInstallationDetails();
        document.title = "Lumi - Detalhes da Instalação";
    }, [numero_instalacao]);

    // Função para calcular as variáveis de interesse por mês de referência
    const calcularPorMes = (): MonthlyCalculation[] => {
        if (!installation) return [];

        // Agrupar os dados por mês de referência
        const agrupamentoMes: { [mes: string]: MonthlyCalculation } = {};

        installation.billingValues.forEach((item) => {
            const mes = item.mes_referencia;
            if (!agrupamentoMes[mes]) {
                agrupamentoMes[mes] = {
                    mesReferencia: mes,
                    consumoEnergiaEletrica: 0,
                    energiaCompensada: 0,
                    valorTotalSemGD: 0,
                    economiaGD: 0,
                };
            }

            // Adicionar os valores de cobrança ao mês correspondente
            if (item.item === 'Energia Elétrica (R$)') {
                agrupamentoMes[mes].valorTotalSemGD += parseFloat(item.valor);
            } else if (item.item === 'Energia SCEE s/ ICMS (R$)') {
                agrupamentoMes[mes].valorTotalSemGD += parseFloat(item.valor);
            } else if (item.item === 'Contrib Ilum Publica Municipal (R$)') {
                agrupamentoMes[mes].valorTotalSemGD += parseFloat(item.valor);
            } else if (item.item === 'Energia compensada GD I (R$)') {
                agrupamentoMes[mes].economiaGD += parseFloat(item.valor);
            }
        });

        installation.consumptionHistory.forEach((item) => {
            const mes = item.mes_ano;
            if (!agrupamentoMes[mes]) {
                agrupamentoMes[mes] = {
                    mesReferencia: mes,
                    consumoEnergiaEletrica: 0,
                    energiaCompensada: 0,
                    valorTotalSemGD: 0,
                    economiaGD: 0,
                };
            }

            // Adicionar os valores de consumo ao mês correspondente
            agrupamentoMes[mes].consumoEnergiaEletrica += item.consumo;
            agrupamentoMes[mes].energiaCompensada += item.media ? parseFloat(item.media) : 0; // Ajuste para adicionar energia compensada
        });

        return Object.values(agrupamentoMes);
    };

    const calculosMensais = calcularPorMes();

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="alert alert-danger my-4">{error}</div>;
    }

    return (
        <div className="container">
            <h1 className="h3 text-center mb-3">Detalhes da Instalação</h1>

            {installation ? (
                <div className="row">
                    {/* Informações da Instalação */}
                    <div className="col-md-12 mb-4">
                        <div className="card shadow-sm">
                            <div className="card-header bg-primary text-white">
                                Informações da Instalação
                            </div>
                            <div className="card-body">
                                <p><strong>Número de Instalação:</strong> {installation.numero_instalacao}</p>
                                <p><strong>Número do Cliente:</strong> {installation.numero_cliente}</p>
                            </div>
                        </div>
                    </div>

                    {/* Cálculo das Variáveis de Interesse por Mês */}
                    <div className="col-md-12 mb-4">
                        <div className="card shadow-sm">
                            <div className="card-header bg-success text-white">
                                Variáveis de Interesse por Mês
                            </div>
                            <div className="card-body p-0">
                                <table className="table table-striped mb-0">
                                    <thead className="table-dark">
                                        <tr>
                                            <th>Mês Referência</th>
                                            <th>Consumo de Energia Elétrica (kWh)</th>
                                            <th>Energia Compensada (kWh)</th>
                                            <th>Valor Total sem GD (R$)</th>
                                            <th>Economia GD (R$)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {calculosMensais.length > 0 ? (
                                            calculosMensais.map((calculo) => (
                                                <tr key={calculo.mesReferencia}>
                                                    <td>{calculo.mesReferencia}</td>
                                                    <td>{calculo.consumoEnergiaEletrica}</td>
                                                    <td>{calculo.energiaCompensada}</td>
                                                    <td>{calculo.valorTotalSemGD.toFixed(2)}</td>
                                                    <td>{calculo.economiaGD.toFixed(2)}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={5} className="text-center">Nenhum dado disponível.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="alert alert-info my-4">Dados não disponíveis.</div>
            )}
        </div>
    );
};

export default InstallationDetails;
