import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/Auth';

export default function SignIn() {
  const { signIn, authError } = useAuth();
  const [formData, setFormData] = useState({ username: 'felipevaz@live.com', password: 'P@ssw0rd' });
  const [errors, setErrors] = useState({ username: false, password: false });
  const [showError, setShowError] = useState<boolean>(!!authError);

  useEffect(() => {
    document.title = "Lumi - Autenticação";
    setShowError(!!authError);
  }, [authError]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { username, password } = formData;
    const validation = handleValidation({ username, password });

    if (validation) {
      signIn(username, password);
    }
  };

  function handleValidation(data: { username: string, password: string }) {
    const errors = {
      username: data.username.trim() === '',
      password: data.password.trim() === '',
    };
    setErrors(errors);
    return !errors.username && !errors.password;
  }

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center p-0">
      <div className="row w-100 m-0">
        <div 
          className="col-md-9 d-none d-md-block p-0" 
          style={{
            backgroundImage: 'url(https://picsum.photos/1024/768)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
          }}
        />
        <div className="col-12 col-md-3 d-flex align-items-center justify-content-center p-0" style={{ height: '100vh' }}>
          <div className="p-4 w-100" style={{ maxWidth: '400px' }}>
            <div className="text-center mb-4">
              <div className="rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center mx-auto" style={{ width: '60px', height: '60px' }}>
                <i className="bi bi-lock fs-3"></i>
              </div>
              <h1 className="h5 mt-3">Autenticar</h1>
            </div>
            <form noValidate onSubmit={handleSubmit} className="mt-3">
              <div className="mb-3">
                <label htmlFor="username" className="form-label">Usuário</label>
                <input
                  type="text"
                  className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  autoFocus
                />
                {errors.username && <div className="invalid-feedback">Campo Obrigatório</div>}
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="form-label">Senha</label>
                <input
                  type="password"
                  className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                {errors.password && <div className="invalid-feedback">Campo Obrigatório</div>}
              </div>
              {showError && (
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                  {authError}
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowError(false)}></button>
                </div>
              )}
              <button type="submit" className="btn btn-primary w-100">Autenticar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
