import React from 'react';
import { useAuth } from '../contexts/Auth';

const Header: React.FC = () => 
{
    const { signOut } = useAuth();

    return (
        <nav className="navbar navbar-expand navbar-light bg-light shadow-sm mb-4">
            <button className="btn btn-link d-md-none" id="sidebarToggleTop">
                <i className="fa fa-bars"></i>
            </button>

            <ul className="navbar-nav ms-auto me-3">
                <li className="nav-item dropdown">
                    <a 
                        className="nav-link dropdown-toggle" 
                        href="#" 
                        id="userDropdown" 
                        role="button" 
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <span className="me-2">User</span>
                        <img 
                            src="https://via.placeholder.com/40" 
                            alt="User" 
                            className="rounded-circle" 
                        />
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                        <li>
                            <button className="dropdown-item" onClick={signOut}> Sair </button>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
};

export default Header;
