import axios from 'axios';

const Api = axios.create({
    baseURL: 'https://api.oovaz.com.br/api',
});

// Configura o cabeçalho Content-Type
Api.defaults.headers.post['Content-Type'] = 'application/json';

// Interceptor para adicionar o token de autenticação
Api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

export default Api;
