import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';

const Layout: React.FC = () => {
    return (
        <div className="d-flex" style={{ height: '100vh', overflow: 'hidden' }}>
            <Sidebar />
            <div className="d-flex flex-column flex-grow-1">
                <Header />
                <main 
                    className="flex-grow-1 p-4 overflow-auto" 
                    style={{ maxHeight: 'calc(100vh - 100px)' }} // Ajusta a altura para permitir rolagem
                >
                    <Outlet />
                </main>
                {/* <Footer /> */}
            </div>
        </div>
    );
};

export default Layout;
