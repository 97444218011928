import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import InputMask from 'react-input-mask';
import Api from '../../configs/Api';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard: React.FC = () => {
    const [numeroCliente, setnumeroCliente] = useState<string>('');
    const [periodo, setPeriodo] = useState<string>('');
    const [dadosFiltrados, setDadosFiltrados] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchDadosDashboard = async () => {
        if (!numeroCliente) {
            setError('Número de instalação é obrigatório.');
            return;
        }
        try {
            setLoading(true);
            setError(null);

            const response = await Api.get(`/installations/${numeroCliente}`);
            if (response.status === 200) {
                const dadosOriginais = response.data;
                filtrarDadosPorPeriodo(dadosOriginais);
            } else {
                setError(`Erro ao buscar dados: ${response.status}`);
            }
        } catch (err) {
            setError('Erro ao buscar dados da API. Por favor, tente novamente.');
        } finally {
            setLoading(false);
        }
    };

    const filtrarDadosPorPeriodo = (dados: any) => {
        if (!periodo) {
            setError('Período é obrigatório.');
            return;
        }

        const [mes, ano] = periodo.split('/');

        const mesesMapa: { [key: string]: string } = {
            '01': 'JAN', '02': 'FEV', '03': 'MAR', '04': 'ABR',
            '05': 'MAI', '06': 'JUN', '07': 'JUL', '08': 'AGO',
            '09': 'SET', '10': 'OUT', '11': 'NOV', '12': 'DEZ'
        };

        const mesReferencia = `${mesesMapa[mes]}/${ano}`;

        const billingFiltrado = dados.billingValues.filter(
            (item: any) => item.mes_referencia === mesReferencia
        );

        const consumoFiltrado = dados.consumptionHistory.find(
            (item: any) => item.mes_ano === mesReferencia
        );

        const consumoEnergia = billingFiltrado
            .filter((item: any) => item.item === 'Energia Elétrica' || item.item === 'Energia SCEE s/ ICMS')
            .reduce((acc: number, item: any) => acc + (parseFloat(item.quantidade) || 0), 0);

        const energiaCompensada = billingFiltrado
            .filter((item: any) => item.item === 'Energia compensada GD I')
            .reduce((acc: number, item: any) => acc + (parseFloat(item.quantidade) || 0), 0);

        const valorTotalSemGD = billingFiltrado
            .filter((item: any) => ['Energia Elétrica', 'Energia SCEE s/ ICMS', 'Contrib Ilum Publica Municipal'].includes(item.item))
            .reduce((acc: number, item: any) => acc + (parseFloat(item.valor) || 0), 0);

        const economiaGD = billingFiltrado
            .filter((item: any) => item.item === 'Energia compensada GD I')
            .reduce((acc: number, item: any) => acc + (parseFloat(item.valor) || 0), 0);

        setDadosFiltrados({
            mesReferencia,
            consumoEnergia,
            energiaCompensada,
            valorTotalSemGD,
            economiaGD,
        });
    };

    const aplicarFiltros = () => {
        if (numeroCliente && periodo) {
            fetchDadosDashboard();
        } else {
            setError('Preencha o número da instalação e o período de análise no formato MM/YYYY.');
        }
    };

    // Configurações dos gráficos de resultados de energia (gráfico de barras)
    const energiaData = {
        labels: [dadosFiltrados?.mesReferencia || ''],
        datasets: [
            {
                label: 'Consumo de Energia (kWh)',
                data: [dadosFiltrados?.consumoEnergia || 0],
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
            {
                label: 'Energia Compensada (kWh)',
                data: [dadosFiltrados?.energiaCompensada || 0],
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Configurações dos gráficos de resultados financeiros (gráfico de barras)
    const financeiroData = {
        labels: [dadosFiltrados?.mesReferencia || ''],
        datasets: [
            {
                label: 'Valor Total sem GD (R$)',
                data: [dadosFiltrados?.valorTotalSemGD || 0],
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
            {
                label: 'Economia GD (R$)',
                data: [dadosFiltrados?.economiaGD || 0],
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <div className="container">
            <h1 className="h3 mb-4">Dashboard</h1>

            {/* Filtros de instalação e período */}
            <div className="row mb-4">
                <div className="col-md-4">
                    <label className="form-label">Número do Cliente</label>
                    <input
                        type="text"
                        className="form-control"
                        value={numeroCliente}
                        onChange={(e) => setnumeroCliente(e.target.value)}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label">Período de Análise (MM/YYYY)</label>
                    <InputMask
                        mask="99/9999"
                        className="form-control"
                        placeholder="Ex: 01/2024"
                        value={periodo}
                        onChange={(e) => setPeriodo(e.target.value)}
                    />
                </div>
                <div className="col-md-4 d-flex align-items-end">
                    <button className="btn btn-primary" onClick={aplicarFiltros}>
                        Aplicar Filtros
                    </button>
                </div>
            </div>

            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visualmente-hidden">Carregando...</span>
                    </div>
                </div>
            ) : error ? (
                <div className="alert alert-danger my-4">{error}</div>
            ) : dadosFiltrados ? (
                <>
                    {/* Cards de resumo */}
                    <div className="row mb-4">
                        <div className="col-md-3">
                            <div className="card text-white bg-primary mb-3">
                                <div className="card-header">Consumo Total (kWh)</div>
                                <div className="card-body">
                                    <h5 className="card-title">{dadosFiltrados.consumoEnergia}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card text-white bg-success mb-3">
                                <div className="card-header">Energia Compensada (kWh)</div>
                                <div className="card-body">
                                    <h5 className="card-title">{dadosFiltrados.energiaCompensada}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card text-white bg-danger mb-3">
                                <div className="card-header">Valor Total sem GD (R$)</div>
                                <div className="card-body">
                                    <h5 className="card-title">{dadosFiltrados.valorTotalSemGD.toFixed(2)}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card text-white bg-info mb-3">
                                <div className="card-header">Economia GD (R$)</div>
                                <div className="card-body">
                                    <h5 className="card-title">{dadosFiltrados.economiaGD.toFixed(2)}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Gráfico de resultados de energia */}
                    <div className="mb-4">
                        <h5>Resultados de Energia (kWh)</h5>
                        <Bar data={energiaData} options={{ responsive: true }} />
                    </div>

                    {/* Gráfico de resultados financeiros */}
                    <div className="mb-4">
                        <h5>Resultados Financeiros (R$)</h5>
                        <Bar data={financeiroData} options={{ responsive: true }} />
                    </div>
                </>
            ) : (
                <div className="alert alert-info my-4">Nenhum dado encontrado para o período selecionado.</div>
            )}
        </div>
    );
};

export default Dashboard;
